<template>
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="60px"
    >
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="部门:" prop="depart_id">
        <el-select
          v-model="form.depart_id"
          clearable
          style="width: 140px"
          placeholder="请选择部门"
        >
          <el-option
            v-for="(i, idx) in bmList"
            :key="idx"
            :value="i.id"
            :label="i.text"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="角色:" prop="role_id">
        <el-select
          v-model="form.role_id"
          clearable
          style="width: 140px"
          placeholder="请选择角色"
        >
          <el-option
            v-for="(i, idx) in roleSelect"
            :key="idx"
            :value="i.role_id"
            :label="i.role_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="user_id">
        <el-select
          v-model="form.user_id"
          clearable
          filterable
          style="width: 140px"
          placeholder="员工姓名、电话"
        >
          <el-option
            v-for="(i, idx) in peopleSelect"
            :key="idx"
            :value="i.user_id"
            :label="i.user_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button
          type="primary"
          @click="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <!-- <el-form-item label="" prop="">
        <el-button>打印</el-button>
      </el-form-item> -->
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleSet">预警设置</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <el-dialog
      :modal="false"
      title="停留超时预警设置"
      :visible.sync="showDialog"
      width="500px"
      top="25vh"
      :close-on-click-modal="false"
      destroy-on-close
      center
    >
      <div class="orderTest-container">
        <div style="height: 95px; line-height: 75px; text-align: center">
          停留预警时长:
          <el-input
            v-model="timeOut"
            style="width: 110px"
            :min="0"
            type="number"
          ></el-input>
          分钟
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: 5px; text-align: right"
      >
        <el-button type="primary" @click="handleSave">确定</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { timeOutList, setTimeOut, roleList } from '@/api/warning'
  import { departmentList } from '@/api/performance'
  import { getStaffList } from '@/api/setPrice'
  import { peopleList } from '@/api/performance'
  export default {
    name: 'TimeOut',
    components: {},
    data() {
      return {
        loading: false,
        showDialog: false,
        timeOut: '',
        bmList: [],
        staffList: [],
        roleSelect: [],
        peopleSelect: [],
        list: [],
        time: [],
        checkList: [
          '姓名',
          '部门',
          '角色',
          '电话',
          '客户名称',
          '停留地点',
          '起止时间',
          '停留时长',
          '预警日期',
        ],
        columns: [
          {
            order: 1,
            label: '姓名',
            prop: 'user_name',
            width: '',
          },
          {
            order: 2,
            label: '部门',
            prop: 'depart_name',
            width: '',
          },
          {
            order: 3,
            label: '角色',
            prop: 'user_role',
            width: '220',
          },
          {
            order: 4,
            label: '电话',
            prop: 'mobile',
            width: '110px',
          },
          {
            order: 5,
            label: '客户名称',
            prop: 'cust_name',
            width: '180px',
          },
          {
            order: 7,
            label: '停留地点',
            prop: 'address',
            width: '200px',
          },
          {
            order: 8,
            label: '起止时间',
            prop: 's_e_time',
            width: '150px',
          },
          {
            order: 9,
            label: '停留时长',
            prop: 'stay_time',
            width: '110px',
          },
          {
            order: 8,
            label: '预警日期',
            prop: 'warning_time',
            width: '110px',
          },
        ],
        form: {
          pageNo: 1, //当前页
          pageSize: 10, //页条数
          end_time: '',
          start_time: '',
          role_id: '',
          user_id: '', //业务员ID
          depart_id: '', //部门ID
        },
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
      showDialog(val) {
        if (!val) {
          this.timeOut = ''
        }
      },
    },
    created() {
      this.fetchData()
      departmentList().then((res) => {
        this.bmList = res.data.list
      })
      getStaffList().then((res) => {
        this.staffList = res.data
      })
      roleList().then((res) => {
        this.roleSelect = res.data
      })
      // 员工下拉
      peopleList({ pageSize: -1 }).then((res) => {
        console.log(res)
        this.peopleSelect = res.data
      })
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, code, totalCount } = await timeOutList(this.form)
        console.log(data)
        this.list = data
        this.total = totalCount
        this.loading = false
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      close() {
        this.showDialog = false
      },
      handleSet() {
        this.showDialog = true
      },
      handleSave() {
        setTimeOut({ stop_remain: this.timeOut }).then((res) => {
          this.$message.success(res.msg)
          this.fetchData()
          this.close()
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
